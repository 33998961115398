import '../css/index.css'
import Swiper from '../lib/swiper.min';
import videojs from 'video.js';
var swiper = new Swiper('.swiper-container', {
  spaceBetween: 30,
  effect: 'fade',
  centeredSlides: true,
  autoHeight: true, //高度随内容变化
  // width: window.innerWidth,
  speed: 500,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    // nextEl: '.swiper-button-next',
    // prevEl: '.swiper-button-prev',
  },
  on: {
    // resize: function () {
    //   this.params.width = window.innerWidth;
    //   this.update();
    // },
  }
});
var img = "http://vjs.zencdn.net/v/oceans.png"
var Component = videojs.getComponent('Component')


var TitleBar = videojs.extend(Component, {
  constructor: function (player, options) {
    Component.apply(this, arguments)

    // 如果在options中传了text属性，那么更新这个组件的文字显示
    if (options.text) {
      this.updateTextContent(options.text);
    }
  },
  // 创建一个DOM元素
  createEl: function () {
    return videojs.dom.createEl('div', {

      // Prefixing classes of elements within a player with "vjs-"
      // is a convention used in Video.js.
      //给元素加vjs-开头的样式名，是videojs内置样式约定俗成的做法
      className: 'vjs-title-bar'
    });
  },
  updateTextContent: function (text) {

    // If no text was provided, default to "Text Unknown"
    // 如果options中没有提供text属性，默认显示Text Unknow
    if (typeof text !== 'string') {
      text = 'Text Unknown';
    }
    // Use Video.js utility DOM methods to manipulate the content
    // of the component's element.
    // 使用Video.js提供的DOM方法来操作组件元素
    videojs.dom.emptyEl(this.el());
    videojs.dom.appendContent(this.el(), text);
  }
})
// 在videojs中注册这个组件，才可以使用哦.
videojs.registerComponent('TitleBar', TitleBar);
var player = videojs('lbc-video', {
  muted: true,
  controls: false,
  height: 762,
  loop: true,
  preload: 'auto',
  poster: ''
}, function onPlayReady() {
  this.play()
});
